// Scroll to message
function scrollToFormMessage() {
  let alert = document.querySelector('.cm-form-contact .alert')
  if(!alert) return
  alert.scrollIntoView()
}

// Hide Stoerer if no Form is present
function hideStoerer() {
  const stoerers = document.querySelectorAll('.cm-stoerer')
  if(!stoerers) return

  stoerers.forEach((stoerer) => {
    let a = stoerer.querySelector('a[href^="#"]')
    let formId = a.href.substring(a.href.lastIndexOf('#') + 1)
    if(!document.getElementById(formId)) {
      a.setAttribute('hidden','')
    }
  })
}

scrollToFormMessage()
hideStoerer()
document.querySelectorAll('[data-gtm]').forEach(el => {
  let events = JSON.parse(el.dataset.gtm)
  if(!events) return

  for(const [key, value] of Object.entries(events)) { 
    el.addEventListener(key, e => {
      sendGTMEvent(value)
    })
  }
})

function sendGTMEvent(event) {
  if((typeof dataLayer === 'object') && (typeof dataLayer.push === 'function')) {
    console.log('gtm-event:', event);
    dataLayer.push({'event': event});
  }
}
import Splide from '@splidejs/splide';

function getFirstNode(nodeList) {
  return [...nodeList].filter((node) => node.tagName == 'LI')[0];
}

function getIndexOfElement(element) {
  return Array.from(element.parentElement.children).indexOf(element);
}

const inNeosBackend = window.name == 'neos-content-main'
const splideElements = document.querySelectorAll('.splide')
let splides = []
const splideSettings = {
  arrowPath: 'M16.027,19.998L29.263,6.772C29.735,6.303 30,5.665 30,5C30,3.629 28.871,2.5 27.5,2.5C26.832,2.5 26.192,2.767 25.722,3.242L10.722,18.228C9.788,19.168 9.756,20.696 10.649,21.675L25.712,36.765C26.182,37.24 26.822,37.507 27.49,37.507C28.861,37.507 29.99,36.379 29.99,35.007C29.99,34.343 29.725,33.704 29.253,33.235L16.027,19.998Z',
  i18n:  {
    de: {
      prev: 'Vorheriges Slide',
      next: 'Nächstes Slide',
      first: 'Gehe zum ersten Slide',
      last: 'Gehe zum letzten Slide',
      slideX: 'Gehe zu Slide %s',
      pageX: 'Gehe zu Seite %s',
      play: 'Starte Autoplay',
      pause: 'Pausiere Autoplay',
      carousel: 'Karusell',
      select: 'Wählen Sie ein Slide zum Anzeigen',
      slide: 'Slide',
      slideLabel: '%s von %s'
    }
  }
}

// 
splideElements.forEach((splideElement, i) => {
  splides[i] = new Splide(splideElement, {
    type: 'loop',
    perPage: 3,
    perMove: 1,
    gap: 'var(--gutter)',
    arrows: true,
    pagination: false,
    i18n: splideSettings.i18n['de'],
    arrowPath: splideSettings.arrowPath,
    breakpoints: {
      1200: {
        perPage: 2
      },
      768: {
        perPage: 1
      }
    }
  });
})

// We are in the backend, so we need to refresh the instance on change
if (inNeosBackend) {
  splides.forEach((splide, i) => {
    splide.on('mounted', function () {
      // Update if a slide is added or removed
      const observeTarget = splideElements[i].querySelector('.splide__list');
      const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
              const addedNode = getFirstNode(mutation.addedNodes);
              const removedNode = getFirstNode(mutation.removedNodes);
              if (addedNode || removedNode) {
                  console.log('Refreshing instance');
                  splide.refresh();
              }
              if (addedNode) {
                  // Scroll to the new slide
                  splide.go(getIndexOfElement(addedNode));
              }
          });
      });
      observer.observe(observeTarget, { childList: true });

      // Go to the slide if it gets selceted in the node tree
      document.addEventListener('Neos.NodeSelected', (event) => {
          const element = event.detail.element;
          if (!element.classList.contains('splide__slide')) {
              return;
          }
          splide.go(getIndexOfElement(element));
      });
    });
  })
}

// mount all splides
splides.forEach(splide => {
  splide.mount()
})
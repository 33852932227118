import '../../../../node_modules/scroll-timeline-polyfill/dist/scroll-timeline';

if(window.matchMedia('(min-width: 768px)').matches) {
  const cmTextImageScrollElements = document.querySelectorAll('.cm-text-image-scroll')
  cmTextImageScrollElements.forEach((cmTextImageScroll) => {
    const cmTextImageScrollImages = cmTextImageScroll.querySelector('.cm-text-image-scroll__images ul')
    if(!(cmTextImageScrollImages && cmTextImageScrollImages)) return

    cmTextImageScrollImages.animate(
      { 
        transform: ['translateY(0)', 'translateY(calc(var(--_images-height) - 100%))']
      },
      { 
        timeline: new ViewTimeline({
          subject: cmTextImageScroll,
        }),
        fill: 'both',
        rangeStart:  { rangeName: 'entry', offset: CSS.percent(50) },
        rangeEnd:  { rangeName: 'exit', offset: CSS.percent(-10) }
      }
    )
  })

}
const tabPaneElements = document.querySelectorAll('.cm-tab-panes')

tabPaneElements.forEach(tabPanes => {
  // let tabsContainer = tabPanes.querySelectorAll('.cm-tab-panes__tabs')
  let tabs = tabPanes.querySelectorAll('.cm-tab-panes__tab')
  // let panesContainer = tabPanes.querySelectorAll('.cm-tab-panes__panes')
  let panes = tabPanes.querySelectorAll('.cm-tab-panes__pane')

  // console.log(tabsContainer, tabs)
  // console.log(panesContainer, panes)

  tabs.forEach((tab, index) => {
    if(index === 0) {

    } else {
      panes[index].setAttribute('hidden', '')
    }

    tab.addEventListener('click', (e) => {
      e.preventDefault()
      let tabClicked = e.currentTarget
      let paneToShowId = tabClicked.getAttribute('aria-controls')
      console.log(paneToShowId)
      // let paneToShow = panesContainer.querySelector('[id=' + paneToShowId + ']')
      let paneToShow = tabPanes.querySelector('[id="' + paneToShowId + '"]')
      panes.forEach((pane) => {
        pane.setAttribute('hidden', '')
      })
      paneToShow.removeAttribute('hidden')
      tabs.forEach((tab) => {
        tab.setAttribute('aria-selected', 'false')
      })
      tabClicked.setAttribute('aria-selected', 'true')
    })

  })
})
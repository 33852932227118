import {klaroConfig} from './klaro-config'
import * as klaro from 'klaro'

// Klaro
klaro.render(klaroConfig);
document.querySelectorAll('[data-klaro-service]').forEach(el => {
  el.addEventListener('click', e => {
    klaro.getManager(klaroConfig).updateConsent(el.dataset.klaroService, true);
    klaro.getManager(klaroConfig).saveAndApplyConsents();
  });
});
document.querySelectorAll('[data-klaro-settings]').forEach(el => {
  el.addEventListener('click', e => {
    klaro.show(klaroConfig);
    e.preventDefault();
  });
});

window.klaro = klaro
window.klaroConfig = klaroConfig
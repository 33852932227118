document.querySelectorAll('[data-dialog]').forEach((a) => {
  let dialogId = a.dataset.dialog
  if(!dialogId) return
  let dialog = document.getElementById(dialogId)
  if(!dialog) return
  a.addEventListener('click', (e) => {
    e.preventDefault()
    dialog.showModal()
  })
})

// Close Dialog if Backdrop is clicked
document.querySelectorAll('dialog').forEach((dialog) => {
  // Handler for Close-Button
  let dialogClose = dialog.querySelector('.dialog__close');
  if(dialogClose) {
    dialogClose.addEventListener('click', (e) => {
      dialog.close()
    })
  }
  // Close if backdrop is clicked
  dialog.addEventListener('click', (e) => {
    if(e.target.nodeName === 'DIALOG') {
      dialog.close()
    }
  })
})